.connect-button {
  --ck-font-family: "Inter";
  --ck-font-weight: 400;
  --ck-border-radius: 0px;
  --ck-overlay-backdrop-filter: blur(0px);
  --ck-modal-heading-font-weight: 500;
  --ck-qr-border-radius: 16px;
  --ck-connectbutton-font-size: 15px;
  --ck-connectbutton-color: #373737;
  --ck-connectbutton-background: #f6f7f9;
  --ck-connectbutton-background-secondary: #ffffff;
  --ck-connectbutton-border-radius: 16px;
  --ck-connectbutton-box-shadow: 0 0 0 0 #ffffff;
  --ck-connectbutton-hover-color: #373737;
  --ck-connectbutton-hover-background: #f0f2f5;
  --ck-connectbutton-hover-box-shadow: 0 0 0 0 #ffffff;
  --ck-connectbutton-active-color: #373737;
  --ck-connectbutton-active-background: #eaecf1;
  --ck-connectbutton-active-box-shadow: 0 0 0 0 #ffffff;
  --ck-connectbutton-balance-color: #373737;
  --ck-connectbutton-balance-background: #fff;
  --ck-connectbutton-balance-box-shadow: inset 0 0 0 1px #f6f7f9;
  --ck-connectbutton-balance-hover-background: #f6f7f9;
  --ck-connectbutton-balance-hover-box-shadow: inset 0 0 0 1px #f0f2f5;
  --ck-connectbutton-balance-active-background: #f0f2f5;
  --ck-connectbutton-balance-active-box-shadow: inset 0 0 0 1px #eaecf1;
  --ck-primary-button-font-weight: 400;
  --ck-primary-button-border-radius: 0px;
  --ck-primary-button-color: #a82e26;
  --ck-primary-button-background: #000000;
  --ck-primary-button-box-shadow: 1px 1px 1px 0px #a82e26;
  --ck-primary-button-hover-color: #373737;
  --ck-primary-button-hover-background: #f0f2f5;
  --ck-primary-button-hover-box-shadow: 0 0 0 0 #ffffff;
  --ck-primary-button-active-color: #373737;
  --ck-primary-button-active-background: #f0f2f5;
  --ck-primary-button-active-box-shadow: 0 0 0 0 #ffffff;
  --ck-secondary-button-font-weight: 500;
  --ck-secondary-button-border-radius: 16px;
  --ck-secondary-button-color: #ffffff;
  --ck-secondary-button-background: #f6f7f9;
  --ck-secondary-button-box-shadow: 0 0 0 0 #ffffff;
  --ck-secondary-button-hover-color: #373737;
  --ck-secondary-button-hover-background: #dfe4ec;
  --ck-secondary-button-hover-box-shadow: 0 0 0 0 #ffffff;
  --ck-secondary-button-active-color: #373737;
  --ck-secondary-button-active-background: #dfe4ec;
  --ck-secondary-button-active-box-shadow: 0 0 0 0 #ffffff;
  --ck-tertiary-button-font-weight: 500;
  --ck-tertiary-button-border-radius: 16px;
  --ck-tertiary-button-color: #ffffff;
  --ck-tertiary-button-background: #f6f7f9;
  --ck-tertiary-button-box-shadow: 0 0 0 0 #ffffff;
  --ck-tertiary-button-hover-color: #373737;
  --ck-tertiary-button-hover-background: #f6f7f9;
  --ck-tertiary-button-hover-box-shadow: 0 0 0 0 #ffffff;
  --ck-tertiary-button-active-color: #373737;
  --ck-tertiary-button-active-background: #f6f7f9;
  --ck-tertiary-button-active-box-shadow: 0 0 0 0 #ffffff;
  --ck-modal-box-shadow: 0px 2px 4px 0px #00000005;
  --ck-overlay-background: #00000008;
  --ck-body-color: #d7d7d7;
  --ck-body-color-muted: #999999;
  --ck-body-color-muted-hover: #111111;
  --ck-body-background: #000000;
  --ck-body-background-transparent: rgba(255, 255, 255, 0);
  --ck-body-background-secondary: #f6f7f9;
  --ck-body-background-secondary-hover-background: #e0e4eb;
  --ck-body-background-secondary-hover-outline: #4282ff;
  --ck-body-background-tertiary: #f3f4f7;
  --ck-body-action-color: #999999;
  --ck-body-divider: #f7f6f8;
  --ck-body-color-danger: #ff4e4e;
  --ck-body-color-valid: #32d74b;
  --ck-siwe-border: #f0f0f0;
  --ck-body-disclaimer-background: #f6f7f9;
  --ck-body-disclaimer-color: #aaaaab;
  --ck-body-disclaimer-link-color: #838485;
  --ck-body-disclaimer-link-hover-color: #000000;
  --ck-tooltip-background: #ffffff;
  --ck-tooltip-background-secondary: #ffffff;
  --ck-tooltip-color: #999999;
  --ck-tooltip-shadow: 0px 2px 10px 0 #00000014;
  --ck-dropdown-button-color: #999999;
  --ck-dropdown-button-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.01),
    0px 0px 7px rgba(0, 0, 0, 0.05);
  --ck-dropdown-button-background: #fff;
  --ck-dropdown-button-hover-color: #8b8b8b;
  --ck-dropdown-button-hover-background: #f5f7f9;
  --ck-qr-dot-color: #000000;
  --ck-qr-background: #ffffff;
  --ck-qr-border-color: #f7f6f8;
  --ck-focus-color: #1a88f8;
  --ck-spinner-color: #1a88f8;
  --ck-copytoclipboard-stroke: #cccccc;
  --ck-recent-badge-color: #777;
  --ck-recent-badge-background: #f6f7f9;
  --ck-recent-badge-border-radius: 32px;
}
