@font-face {
  font-family: "BodoniModa";
  src: local("BodoniModa"), url(./fonts/BodoniModa.ttf) format("truetype");
}

@font-face {
  font-family: "BodoniModaHeading";
  font-weight: 700;
  src: local("BodoniModa"), url(./fonts/BodoniModa.ttf) format("truetype");
}

@font-face {
  font-family: "CloisterBlack";
  src: local("CloisterBlack"), url(./fonts/CloisterBlack.ttf) format("truetype");
}

@font-face {
  font-family: "CloisterBlackHeading";
  font-weight: 700;
  src: local("CloisterBlack"), url(./fonts/CloisterBlack.ttf) format("truetype");
}

@font-face {
  font-family: "Pertili";
  src: local("Pertili"), url(./fonts/Pertili.ttf) format("truetype");
}

@font-face {
  font-family: "PertiliHeading";
  font-weight: 700;
  src: local("Pertili"), url(./fonts/Pertili.ttf) format("truetype");
}

@font-face {
  font-family: "MinionProRegular";
  src: local("MinionProRegular"),
    url(./fonts/minion-pro/MinionPro-Regular.otf) format("truetype");
}

@font-face {
  font-family: "MinionProHeading";
  font-weight: 700;
  src: local("MinionPro-Bold"),
    url(./fonts/minion-pro/MinionPro-Bold.otf) format("truetype");
}
