.title {
  font-size: 1.5em;
}

.content {
  font-size: 1.2em;
}

.description {
  font-size: 0.9em;
}
